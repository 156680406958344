import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

window.lazySizes.cfg = window.lazySizes.cfg || {};

// Marker class for all elements which should be lazy loaded (There can be only
// one class
window.lazySizes.cfg.lazyClass = 'js-lazyload';

// This class will be added to img element as soon as image loading starts. Can
// be used to add unveil effects.
window.lazySizes.cfg.loadingClass = 'is-lazyloading';

// This class will be added to any element as soon as the image is loaded or the
// image comes into view. Can be used to add unveil effects or to apply styles.
window.lazySizes.cfg.loadedClass = 'is-lazyloaded';

// "Note: You can speed up initial loading of in view images if you call
// lazySizesConfig.init() explicitly after lazysizes and all plugins are
// loaded."
window.lazySizes.init();
